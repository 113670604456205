import LinkComponent from '@/src/components/ui/LinkComponent';
import clsx from 'clsx';
import { IArticleItem } from '@/types/name';
import TagsList from '@/src/components/elements/TagsList';
import { formatDate } from '@/src/utils';
import ImageWrapper from '@/src/components/elements/ImageWrapper';

type ArticleItemType = Pick<
    IArticleItem,
    'title' | 'tags' | 'createdAt' | 'imageUrl' | 'slug'
> & {
    description: string;
    activeTag?: string[];
    isSmallSize?: boolean;
};

const ArticleItem = ({
    title,
    tags,
    createdAt,
    imageUrl,
    description,
    activeTag,
    slug,
    isSmallSize,
}: ArticleItemType) => {
    const itemClass = clsx(
        'rounded-lg p-15 bg-secondary/30 border border-secondary relative grid grid-cols-[auto_1fr] gap-x-20 md:p-12 text-left w-full',
        isSmallSize ? 'xl:gap-x-10 lg:gap-x-20' : 'gap-y-20',
    );
    const imgClass = clsx(
        isSmallSize
            ? 'w-160 h-160 xl:w-120 xl:h-120 lg:w-200 lg:h-200'
            : 'w-200 h-200',
        'rounded-lg shrink-0 bg-secondary/30 md:w-150 md:h-150',
    );
    const titleClass = clsx(
        'text-20 font-semibold lg:line-clamp-2 md:text-sm',
        isSmallSize && 'line-clamp-2',
    );
    const descriptionClass = clsx(
        'text-base text-extraLightGray  text-ellipsis overflow-hidden md:text-12 md:line-clamp-5',
        isSmallSize ? 'line-clamp-3 lg:line-clamp-4' : 'line-clamp-4',
    );

    return (
        <li className={itemClass}>
            {imageUrl && (
                <LinkComponent
                    href={`/article/${slug}`}
                    className="row-start-1 row-span-2 col-span-1 lg:row-span-1"
                >
                    <ImageWrapper
                        src={imageUrl}
                        width={200}
                        height={200}
                        alt={title}
                        containerClass={imgClass}
                    />
                </LinkComponent>
            )}

            <div className="pr-20  row-start-1 row-span-1 col-start-2 col-span-1 ">
                <LinkComponent
                    href={`/article/${slug}`}
                    className="flex flex-col gap-10 lg:gap-4"
                >
                    <div className={titleClass}>{title}</div>
                    {createdAt && (
                        <div className="text-base whitespace-nowrap md:text-12 text-extraLightGray">
                            Published:
                            <span className="ml-3 underline font-semibold md:no-underline">
                                {formatDate(createdAt)}
                            </span>
                        </div>
                    )}

                    <div className={descriptionClass}>{description}</div>
                </LinkComponent>
            </div>
            {!isSmallSize && (
                <div className="row-start-2 row-span-1 col-start-2 col-span-1 lg:col-start-1 lg:col-span-2">
                    {tags && <TagsList tags={tags} activeTag={activeTag} />}
                </div>
            )}
        </li>
    );
};

export default ArticleItem;
