import { IArticleItem, IProductItem } from '@/types/name';

enum ResultArrayItemType {
    PRODUCT = 'product',
    ARTICLE = 'article',
}

type ProductItemWithType = IProductItem & {
    type: ResultArrayItemType.PRODUCT;
};
type ArticleItemWithType = IArticleItem & {
    type: ResultArrayItemType.ARTICLE;
};

type ResultArray = (ProductItemWithType | ArticleItemWithType)[];

export const mixArrays = (
    arr1: IProductItem[],
    arr2: IArticleItem[],
): ResultArray => {
    let result: ResultArray = [];
    let i = 0,
        j = 0;

    while (i < arr1.length || j < arr2.length) {
        // Добавляем 3 элемента из первого массива
        for (let k = 0; k < 3 && i < arr1.length; k++) {
            result.push({ ...arr1[i], type: ResultArrayItemType.PRODUCT });
            i++;
        }

        // Добавляем 1 элемент из второго массива
        if (j < arr2.length && arr2[j]) {
            result.push({ ...arr2[j], type: ResultArrayItemType.ARTICLE });
            j++;
        }
    }

    return result;
};

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
};
