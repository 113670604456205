'use client';

import { getArticles, getProducts } from '@/src/app/actions';
import { IArticleItem, IProductItem } from '@/types/name';
import ProductItem from '@/src/components/elements/ProductItem';
import { useLoadMore } from '@/src/hooks/useLoadMore';
import LoadMoreButton from '@/src/components/elements/LoadMoreButton';
import { mixArrays } from '@/src/utils';
import ArticleItem from '@/src/components/elements/ArticleItem';

export interface IProductList {
    productInitialItems: IProductItem[];
    articleInitialItems: IArticleItem[];
    nextPageProducts?: number;
    nextPageArticles?: number;
    tag?: string[];
}

const ItemsListByTag = ({
    productInitialItems,
    articleInitialItems,
    nextPageProducts,
    nextPageArticles,
    tag,
}: IProductList) => {
    const {
        items: articleItems,
        isLoading: articlesIsLoading,
        onClickLoad: onClickLoadArticles,
        isNextPageAvailable: ArticlesIsNextPageAvailable,
    } = useLoadMore({
        initialItems: articleInitialItems,
        nextPage: nextPageArticles,
        request: getArticles,
        tag,
    });

    const {
        items: productItems,
        isLoading: productsIsLoading,
        onClickLoad: onClickLoadProducts,
        isNextPageAvailable: ProductsIsNextPageAvailable,
    } = useLoadMore({
        initialItems: productInitialItems,
        nextPage: nextPageProducts,
        request: getProducts,
        tag,
    });

    const resultArray = mixArrays(productItems, articleItems);

    const handleClickLoad = async () => {
        await Promise.all([onClickLoadProducts(), onClickLoadArticles()]);
    };

    return (
        <>
            <ul className="flex flex-col gap-10">
                {resultArray.map((item, i) =>
                    item.type === 'product' ? (
                        <ProductItem
                            key={`${i}_${item.productId}`}
                            title={item.title}
                            price={item.price}
                            oldPrice={item.oldPrice}
                            description={item.description}
                            tags={item.tags}
                            url={item.url}
                            prisradarUrl={item.prisradarUrl}
                            imageUrl={item.imageUrl}
                            discountPercent={item.discountPercent}
                            discountPrice={item.discountPrice}
                            activeTag={tag}
                            publishedAt={item.publishedAt}
                            isExpired={item.isExpired}
                        />
                    ) : (
                        <ArticleItem
                            key={item.id}
                            title={item.title}
                            slug={item.slug}
                            description={item.intro}
                            tags={item.tags}
                            imageUrl={item.imageUrl}
                            createdAt={item.createdAt}
                            activeTag={tag}
                        />
                    ),
                )}
            </ul>

            {(ArticlesIsNextPageAvailable || ProductsIsNextPageAvailable) && (
                <LoadMoreButton
                    onClickLoad={handleClickLoad}
                    isLoading={productsIsLoading || articlesIsLoading}
                />
            )}
        </>
    );
};

export default ItemsListByTag;
